import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import styled from 'styled-components';
import Title from '../components/Title';
import EmbedContainer from '../components/EmbedContainer';
import { green, tablet, red, desktop } from '../constants';
import SEO from '../components/seo';

export const query = graphql`
  query ProjectsQuery($uid: String) {
    prismic {
      allProjects(uid: $uid) {
        edges {
          node {
            title
            body {
              ... on PRISMIC_ProjectBodyText {
                type
                primary{
                  text
                  size
                }
              }
              ... on PRISMIC_ProjectBodyQuote {
                type
                primary{
                  quote
                  attribution
                }
              }
              ... on PRISMIC_ProjectBodyVimeo_link {
                type
                primary{
                  vimeo_link
                }
              }
              ... on PRISMIC_ProjectBodyImage {
                type
                primary{
                  image
                }
              }
            }
            _meta {
              uid
            }
          }
        }
      }
      category_list(uid: "categories", lang: "en-gb") {
        categories {
          category {
            ... on PRISMIC_Category {
              title
              _meta {
                uid
              }
            }
          }
        }
      }
    }
  }
`;

const StyledEmbedContainer = styled(EmbedContainer)`
  margin: 2rem 0;
`;

const Body = styled.div`
  & p {
    line-height: 1.5;
    color: ${green};
    font-weight: 300;
    margin: 0 auto 3rem;
    margin-bottom: 1rem;
  }

  & h2 {
    color: ${red};
    margin-bottom: 0.5rem;
    font-weight: 800;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  margin-bottom: 1.5rem;
`;

const CTASection = styled.div`
  display: block;
  margin: 2rem auto 4rem;
  text-align: center;
`;

const CTA = styled(Link)`
  padding: 12px;
  margin 20px;
  font-weight: 500;
  font-size: 1.5rem;
  color: ${red};
`;

const ImageWrapper = styled.div`
  margin: 0 -1rem 1rem;

  @media (min-width: ${tablet}) {
    margin: 3rem 0;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const Quote = styled.blockquote`
  margin-left: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (min-width: ${desktop}) {
    margin-left: 3rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
`;

const QuoteWrapper = styled.div`
  font-size: 1.25rem;
  font-style: italic;
  border-left: 1px solid ${green};
  padding-left: 1rem;

  @media (min-width: ${tablet}) {
    font-size: 1.5rem;
  }

  @media (min-width: ${desktop}) {
    font-size: 1.75rem;
  }
`;

const Attribution = styled.div`
& p {
  font-size: 1.25rem;
  font-weight: 600;
  color: ${red};
  font-style: normal;
  border-left: 1px solid transparent;

  @media (min-width: ${tablet}) {
    font-size: 1.5rem;
  }

  @media (min-width: ${desktop}) {
    font-size: 1.75rem;
  }
}
`;

const fontSizes = {
  Normal: { mobile: 1, tablet: 1.25, desktop: 1.5 },
  Large: { mobile: 1.25, tablet: 1.5, desktop: 1.75 },
  Small: { mobile: 1, tablet: 1, desktop: 1.25 }
};

const TextWrapper = styled.div`
  & p {
    font-size: ${({ size }) => fontSizes[size]?.mobile}rem;

    @media (min-width: ${tablet}) {
      font-size: ${({ size }) => fontSizes[size]?.tablet}rem;
    }

    @media (min-width: ${desktop}) {
      font-size: ${({ size }) => fontSizes[size]?.desktop}rem;
    }
  }

  & a {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: ${red};

    &:hover, &:visited {
      color: ${red}
    }
  }

  & h2 {
    font-size: 1.25rem;

    @media (min-width: ${desktop}) {
      font-size: 1.75rem;
    }
  }
`;

const renderText = ({ primary }, key) => {
  return <TextWrapper key={key} size={primary.size}><RichText render={primary.text} /></TextWrapper>;
};

const renderQuote = ({ primary }, key) => <Quote key={key}>
  <QuoteWrapper>
    <RichText render={primary.quote} />
  </QuoteWrapper>
  <Attribution><RichText render={primary.attribution} /></Attribution>
</Quote>;

const renderVideo = ({ primary }, key) => <StyledEmbedContainer key={key}>
<iframe
  src={`https://player.vimeo.com/video/${primary?.vimeo_link?.video_id}?byline=false&title=false`}
  title={primary?.vimeo_link?.title}
  allowFullScreen
  frameBorder="0"
></iframe>
</StyledEmbedContainer>;

const renderImage = ({ primary }, key) => <ImageWrapper key={key}>
<Image src={primary?.image?.url} alt={primary?.image?.alt} />
</ImageWrapper>;

const sliceMapper = {
  text: renderText,
  quote: renderQuote,
  vimeo_link: renderVideo,
  image: renderImage,
};

const renderBody = (body = []) => {
  if (!body || body.length === 0) {
    return;
  }

  return body.map((slice, key) => {
    const renderFunction = sliceMapper[slice.type];

    if (!renderFunction) {
      return null;
    }

    return renderFunction(slice, key);
  });
}
const Project = ({ data }) => {
  const project = data?.prismic?.allProjects?.edges?.slice(0, 1)?.pop();
  if (!project) return null;

  const { title, body } = project.node;

  const workUid =
    data?.prismic?.category_list?.categories?.[0]?.category?._meta?.uid;

  return (
    <Layout>
      <SEO title={`${RichText.asText(title)}`} />
      <Title>{RichText.asText(title)}</Title>
      <Body>
        {renderBody(body)}
      </Body>
      <CTASection>
        <CTA to={`/category/${workUid}`}>See all Work</CTA>
      </CTASection>
    </Layout>
  );
};

export default Project;
